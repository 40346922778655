<template>
    <div>
        <div class="wrap">
            <div class="logo-box flex">
                <div class="logo">
                    <img :src="env.logo" />
                </div>
                <div class="logo-tit">{{vuex_project}}云后台收银系统</div>
            </div>
            <div class="form-box flex justify-center">
                <el-image class="left-img" :src="env.adv" v-if="env.adv"></el-image>
                <div class="right-box">
                    <div class="title text-center">
                        {{vuex_project}}云后台收银系统
                    </div>
                    <el-form class="form" :model="form" ref="form" label-width="0px">
                        <el-form-item label="" prop="username" :rules="[rules.required, rules.username]">
                            <el-input ref="username" placeholder="请输入用户名" v-model="form.username"
                                @keyup.native.enter="$refs.password.focus()"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="password" :rules="rules.required">
                            <el-input ref="password" placeholder="请输入密码" v-model="form.password" show-password
                                @keyup.native.enter="$refs.checkcode.focus()"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="checkcode" :rules="rules.required">
                            <div class="flex align-center">
                                <el-input ref="checkcode" placeholder="请输入验证码" v-model="form.checkcode"
                                    @keyup.native.enter="onSubmit">
                                </el-input>
                                <el-image :src="checkcodeSrc" class="checkcode" @click="refreshCheckCode()"></el-image>
                            </div>
                        </el-form-item>
                        <!-- <el-form-item class="password-box">
							<div class="flex justify-between">
								<el-checkbox v-model="remember">记住密码</el-checkbox>
								<div class="forget" @click="forget()">忘记密码</div>
							</div>
						</el-form-item> -->
                        <div class="flex margin-top">
                            <el-button class="submit-btn" type="success" @click="onSubmit" :disabled="submitting"
                                :loading="submitting">立即登录</el-button>
                        </div>
                        <el-form-item label="">
                            <div class="flex align-center">
                                <el-checkbox v-model="checked">同意</el-checkbox>
                                <span style="cursor: pointer" @click="dialogFormVisible = true">《用户协议》</span>
                            </div>
                        </el-form-item>
                        <div class="no-account text-center">
                            <span>没有账号？</span>
                            <a :href="vuex_project == 'MOMEN么么侠' ? 'http://memexia.com':'http://leishihk.com/'"
                                target="_blank">申请加盟</a>
                        </div>
                    </el-form>
                    <el-dialog :visible.sync="dialogFormVisible" :modal="false" title="用户协议" center>
                        <div style="font-size: 16px;line-height: 28px">
                            MOMEN么么侠云后台收银系统商家入驻合作协议
                            <br />甲方：江苏么么侠健康咨询有限公司
                            <br />乙方：入驻商家
                            <br />鉴于：
                            <br />1.
                            甲方系依法成立并存续的企业法人，开发、运营、管理微信小程序“MOMEN么么侠云后台收银系统”，“MOMEN么么侠云后台收银系统”致力于为乙方提供展示的线上平台，用户可向乙方进行预约、咨询等。乙方项目内容包括开单、商品管理、服务管理、会员管理以及其他服务内容（具体项目内容以“MOMEN么么侠云后台收银系统”项目列表列明的服务内容为准）。
                            <br />2.
                            乙方系依法成立并存续的企业法人/个体工商户，自愿入驻甲方“MOMEN么么侠云后台收银系统”平台，在“MOMEN么么侠云后台收银系统”上创建店铺并从事商品销售或提供服务，为“MOMEN么么侠云后台收银系统”平台客户提供优质服务。
                            <br />为了更好的提供商家服务、保障消费者权益，甲、乙双方本着平等合作、互惠互利的原则，在符合相关法律法规，不违反市场竞争的情况下，乙方须遵循甲方的运营管理规范，经友好协商，达成以下协议，以资共同遵守。
                            <br />第一条 定义
                            <br />您确认并同意本条款中对于各名词的解释及定义，并同意按照该定义履行相关义务。
                            <br />1.1 平台（以下统称为“平台”）：特指由“MOMEN么么侠云后台收银系统”平台现在或将来拥有合格权限运营/
                            管理的网络服务平台，包括但不限于MOMEN么么侠云后台收银系统、MOMEN么么侠云后台收银系统合作方运营/ 管理的网络服务平台及未来可能新设或合作的网络平台等。
                            <br />1.2 用户：即在平台注册，并使用其本人注册的账户，通过平台展示的信息获取商品/ 服务的个人，可在平台上购买商品或服务。
                            <br />1.3 门店价：签订本协议时，商家提供的商品/ 服务直接对外销售/ 提供时的单份销售价格。
                            <br />1.4 平台价：乙方提供的商品/ 服务通过平台向用户展示的价格。
                            <br />1.5 代收款项：用户通过平台支付的已实际消费的款项，由甲方平台代收。该款项扣除双方约定的微信提现费用后（微信提现服务费以微信平台收取标准为准），由甲方支付给乙方。
                            <br />第二条 合作模式
                            <br />2.1
                            甲方提供MOMEN么么侠云后台收银系统平台、MOMEN么么侠云后台收银系统商家端平台，乙方于MOMEN么么侠云后台收银系统商家端如实填写商户名称、服务分类等基本信息，并上传相关资质证书、身份证件等证明申请成为商家。
                            <br />甲方审核通过后，视为乙方入驻成功。乙方作为商家可在甲方平台发布乙方服务项目的基本信息，通过甲方平台实现乙方商品/ 服务在线展示，并由甲方平台代为收取商品/ 服务对应的款项。
                            <br />2.2 支付、提现规则
                            <br />用户通过甲方平台下单，将款项支付至甲方平台账户。乙方向用户完成项目服务或履行完毕相应义务后，可于平台申请提现，在扣除微信提现费用后甲方于48小时内将相应款项支付至乙方在平台中预留的账户内。本协议履行期间，甲方不收取平台使用费用。

                            <br />2.3
                            甲、乙双方的品牌、商标归各自所有，品牌、商标所有权不因双方合作项目的开展发生任何转移。未经对方同意，任何一方不得使用另一方名称、logo以及涉及相关内容的销售、促销宣传材料及广告、报纸、杂志、宣传单等。
                            <br />2.4 乙方入驻甲方平台后，应保证每月通过平台成交_______单以上，乙方任一月度未满足成交订单数量要求的，甲方有权单方下线乙方项目，并解除本协议。
                            <br />2.5 合作期限： 年，自乙方服务项目在MOMEN么么侠云后台收银系统平台入驻之日起至 年 月 日之日止。合作期限届满，甲乙双方继续合作的，另行签订协议。
                            <br />第三条 甲方权利与义务
                            <br />3.1 甲方可通过适当方式为乙方宣传、推广，具体宣传方式为：甲方为乙方在消费群体中做相关宣传介绍并进行相关推广，以提升乙方的知名度；以及
                            。
                            <br />3.1.1 乙方认可甲方的宣传、推广行为，并授权甲方使用乙方及乙方相关门店的名称、LOGO 、图片、文字、门店、会员优惠等信息。
                            <br />3.1.2 甲方为乙方提供宣传、推广服务所用的物料包，乙方需要支付相应费用，具体收费方式如下： 。
                            <br />3.1.3甲方可为乙方提供促销、引流、推广等增值服务。乙方如需该增值服务，需支付相应费用，届时甲乙双方另行签订补充协议。
                            <br />3.2 甲方有权对乙方在平台上发布的内容、信息进行审核，乙方发布内容涉嫌黄、赌、毒等违反法律法规、政策规定及平台规则的，甲方有权通过甲方端口单方操作，下线该服务项目。
                            <br />3.3
                            甲方有权升级、更新平台、MOMEN么么侠云后台收银系统商家系统，如有变更，甲方将通过前述系统以公告形式作出通知，乙方在平台、系统升级、更新后继续使用的，视为认可甲方升级、更新内容。
                            <br />3.4 除展示信息内容或文字确有错误外，乙方不得要求甲方修改已发布于平台上的乙方信息。
                            <br />3.5
                            乙方在提供服务过程中与用户产生纠纷，用户通过投诉、举报、诉讼、仲裁等途径维权，甲方核实后，有权要求乙方配合解决该纠纷，在该纠纷妥善解决前，甲方有权拒绝向乙方支付涉及纠纷的代收款项，待纠纷妥善解决后结算。
                            <br />第四条 乙方权利与义务
                            <br />4.1
                            乙方一旦注册成功，成为甲方平台商家，乙方有权使用甲方平台。乙方若发现任何非法使用商家账户后台或甲方平台存在安全漏洞、潜在安全隐患的情况，应当立即告知甲方，甲方应当对后台安全承担全部责任。
                            <br />4.2
                            乙方向甲方提供营业执照、商家资质证明、身份证明、工商登记证明、税务登记证明及相应资质证明文件。乙方保证：乙方提供的上述资料、文件真实、合法、准确。因乙方资质原因使本协议无法正常履行或侵犯第三方权益的，乙方自行承担责任。且上述证明资料复印件（乙方应按照甲方要求在证书复印件中进行盖章、签字）作为本协议附件，与本协议具有同等法律效力。
                            <br />4.3
                            乙方通过平台发布、编辑、更新的商户信息（包括但不限于文字、图片、人员资质等）真实准确，并且不侵犯任何人的合法权益。乙方自行负责乙方所发布信息的删除或储存失败等信息灭失责任。
                            <br />4.4 乙方向用户提供商品/ 服务时，应确保与乙方发布的信息一致，应按照本协议约定、信息描述、订单及平台相关规则接受用户的预约和消费。
                            <br />乙方应当于用户下单后20分钟内确认订单信息，乙方超时未确认的或乙方因自身原因无法提供服务的，平台将用户支付的款项原路退回，届时乙方应立即电话通知下单消费者以及甲方，安抚消费者并作出妥善处理措施，乙方自行负责超时未确认订单或因自身原因无法提供服务的责任。
                            <br />用户预约成功后申请取消订单的，乙方应当于接到订单取消通知后为用户取消订单并根据甲方通知做相应调整。
                            <br />4.5 乙方应按照平台价为用户开具相应金额的发票。
                            <br />4.6 无论本协议因任何原因终止，乙方承诺立即停止以任何方式使用甲方或甲方关联方相关的平台、商标、标识、网站名称、网站内容等。
                            <br />4.8
                            乙方保证：乙方提供的商品、服务、商标、品牌、图片等享有合法权利或合法授权，不会侵犯他人的知识产权、企业名称权等权益；且甲方或甲方关联方不会因展示乙方上述信息而遭受来自第三方的任何投诉、诉讼、仲裁等争议，否则由乙方承担全部责任。
                            <br />乙方入驻甲方平台后，乙方或乙方门店有关人员提供服务项目、进行产品推广宣传、销售等经营过程中发生违反有关国家法律、法规等行为或侵害任何第三方权益、造成第三方损失的或有损于甲方及甲方关联方名誉及企业、产品形象等行为的，一切后果由乙方自行承担，且甲方有权单方解除本协议，并通过甲方端口单方操作，下线乙方服务项目。甲方向任何第三方承担赔偿责任或收到罚款、没收、停业整顿等处罚的有权向乙方主张赔偿及相应款项。
                            <br />4.9
                            合作期间，乙方应当向甲方提供甲方平台开展的营销活动和品牌形象展示所需要的所有文字、图片和视频等资料以供甲方创作，乙方保证对其提供的该资料具有完全权利且不会侵犯任何第三方权益，上述资料、文件乙方授权给甲方基于本协议目的进行使用。
                            <br />4.10
                            乙方不得通过任何手段、以任何方式自行或者委托他人进行如下行为（包括但不限于虚拟交易、虚拟验证、自买自卖、伪造网络用户真实消费、体验、刷单等违反平台规则、损害甲方或甲方关联方利益的行为，或伪造、仿冒等其他违反国家法律法规的行为，或其它违反公平竞争诚实信用原则与商业道德的行为）。
                            <br />4.11乙方销售行为以及售后服务不得违反国家法律法规，乙方须保证其销售行为以及售后服务的长期性和有效性（不论乙方与甲方合作是否到期或其他任何原因乙方下架甲方平台的，消费者一旦购买乙方服务或产品的，消费者即与乙方建立相应法律关系），如乙方不能按约、按质提供服务的，应退款给消费者，且由乙方销售行为以及售后服务带来的一切法律责任与甲方无关。
                            <br />4.112 本协议解除或终止后，乙方负责解决并交接好预约成功或已成交客户的后期遗留问题（包括但不限于拖欠客户任何款项、货品、服务、售后，及时兑现承诺等）。
                            <br />第五条 保密条款
                            <br />5.1
                            合作期内各方应保守相对方的商业秘密。本协议中任何一方对从其他方获得的有关该方业务及其他保密事项与专有资料在任何时期均应当予以保密。各方应本着谨慎、诚实的态度，采取任何必要、合理的措施，维护合作期知悉或者持有的任何相对方或者虽属于第三方但相对方承诺有保密义务的技术秘密或其他商业秘密信息，以保持其机密性。
                            <br />5.2 如发现商业秘密被泄露（无论是否因各方过错），各方应当采取有效措施，以防止泄密进一步扩大，并及时通知对方。
                            <br />5.3
                            各方在不确定某一信息是否属于对方保密范围时，应当及时向对方征询，在对方答复征询前，一律视为保密事项；各方在不确定某一保密事项的保密人员范围时，应当及时向对方征询，在对方答复征询前，一律保密。

                            <br />第六条 协议的变更、补充和解除
                            <br />6.1 本协议的任何修改、变更应经协议双方另行协商，并就修改、变更事项共同签署书面协议后方可生效。
                            <br />6.2 发生下列情形之一，本协议解除：
                            <br />（1）本协议期满。
                            <br />（2）经各方当事人协商一致解除。
                            <br />（3）因不可抗力，造成本协议无法履行，甲乙双方均不承担任何违约责任。本协议约定的不可抗力指因自然灾害、政策原因以及其他无法控制的因素使本协无法履行或致使甲方平台出现延缓、崩溃等无法正常使用情形导致线上交易无法完成或丢失有关的信息、记录等。
                            <br />（4）乙方出现如下情形，甲方可单方解除本协议：
                            <br />① 乙方未按照本协议约定履行相关义务；
                            <br />② 乙方提供的商品/服务收到客户差评、投诉、举报、诉讼、仲裁累计达
                            件的；
                            <br />③ 乙方被吊销营业执照或注销的；
                            <br />④ 乙方违反本协议约定的。
                            <br />（5）违反国家法律、法规规定的其他情形。
                            <br />6.3 本协议被解除后，不影响一方要求违约方支付违约金和赔偿损失的权利。
                            <br />第七条 违约责任
                            <br />7.1
                            本协议任何一方出现违反本协议约定内容的，另一方有权解除本协议，违约方应向另一方支付违约金，违约金为乙方通过甲方平台已发生的全部订单总额的30%。违约方给另一方造成损失的，应赔偿另一方的实际损失（包括但不限于律师费、诉讼费、保全费、公告费、差旅费等）。

                            <br />7.2 因乙方与第三方侵权或其他法律纠纷须向第三方承担赔偿、违约等责任或缴纳罚款等行政处罚的，甲方不承担任何责任，甲方因乙方行为向任何第三方承担赔偿或违约或支付任。
                            <br />7.3
                            乙方未履行或未全部履行本协议约定义务的，甲方有权单方解除本协议，并通过甲方端口单方操作，下线乙方服务项目、注销乙方店铺，届时乙方应对已预约成功的用户或已达成交易的用户提供服务项目、维权投诉处理等保障义务。
                            <br />第八条 争议解决
                            <br />8.1 甲乙双方因履行本协议所发生的或与本协议有关的一切争议，应当友好协商解决。如协商不成，任何一方均应当向甲方所在地人民法院提起诉讼。
                            <br />8.2
                            各方均认可本协议中提供的地址作为协议所涉及文书往来或诉讼（仲裁）文书送达地址；并且如涉及诉讼，认可该地址即为受诉法院或仲裁机构送达诉讼文书的送达地址；若按该地址送达的相关文书无人签收或被拒绝签收，则文书退回之日视为送达之日。
                            <br />各方确认的上述送达地址，如任何一方发生变更，应在变更后3日内书面通知其他各方，否则，相关文书送达上述地址视为送达，由此产生的法律后果由被送达方承担。
                            <br />第九条 其他
                            <br />9.1 本协议一式两份，自乙方服务项目在甲方MOMEN么么侠云后台收银系统平台入驻成功之日起生效，本协议甲乙双方各持一份，具备同等法律效力。
                            <br />9.2 本协议已由甲乙双方于本协议载明的时间签署，且甲乙双方确认，已对本协议正文内容全部阅知，对其内容全部认可，无异议。

                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="dialogFormVisible = false">确定</el-button>
                        </div>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import rules from "../common/common.rules";
    export default {
        data() {
            return {
                dialogFormVisible: false,
                remember: false,
                checked: false,
                form: {
                    username: "",
                    password: "",
                    checkcode: ""
                },
                checkcodeSrc: "",
                submitting: false, // 是否提交中
                rules: {
                    ...rules,
                    username: {
                        min: 3,
                        max: 16,
                        message: "长度在 3 到 16 个字符",
                        trigger: "blur",
                    },
                },
                env: {
                    logo: '',
                    adv: ''
                }
            };
        },
        mounted() {
            let browser = this.getBrowserInfo()
            let b_name = (browser + "").replace(/[0-9./]/ig, ""); //根据正则将所有数字、‘.’‘/’全部去掉，剩下浏览器名
            let b_version = parseInt((browser + "").replace(/[^0-9.]/ig, "")); //根据正则将所有非数字全部去掉，剩下版本
            console.log(b_name, b_version)
            if (b_name == 'IE' && b_version < 11) {
                alert('您的浏览器内核版本过低，可能会导致部分功能缺失或无法使用!')
            }
            if (b_name == 'firefox' && b_version < 22) {
                alert('您的浏览器内核版本过低，可能会导致部分功能缺失或无法使用!')
            }
            if (b_name == 'chrome' && b_version < 24) {
                alert('您的浏览器内核版本过低，可能会导致部分功能缺失或无法使用!')
            }
            if (b_name == 'safari' && b_version < 24) {
                alert('您的浏览器内核版本过低，可能会导致部分功能缺失或无法使用!')
            }
            if (b_name == 'oprea' && b_version < 16) {
                alert('您的浏览器内核版本过低，可能会导致部分功能缺失或无法使用!')
            }
            this.refreshCheckCode()
            this.$refs.username.focus()
            // 判断环境样式
            if (this.vuex_project === 'MOMEN么么侠') {
                this.env.logo = require('../assets/logo.png');
                this.env.adv = 'https://qiniu.memexia.com/login-img.png'
            } else {
                this.env.logo = require('../assets/ls-logo.png');
                this.env.adv = 'https://qiniu.memexia.com/leishi/ls-login-img.jpg'
            }
        },
        methods: {
            // 忘记密码
            forget() {
                this.$alert('请联系客服')
            },
            // 刷新验证码
            refreshCheckCode() {
                // 获取图片验证码
                this.$api.user.getCheckCode().then(res => {
                    this.checkcodeSrc = res.data.img
                })
            },
            getBrowserInfo() {
                var agent = navigator.userAgent.toLowerCase();
                var regStr_ff = /firefox\/[\d.]+/gi
                var regStr_chrome = /chrome\/[\d.]+/gi;
                var regStr_saf = /safari\/[\d.]+/gi;
                var isIE = agent.indexOf("compatible") > -1 && agent.indexOf("msie" > -1); //判断是否IE<11浏览器
                var isIE11 = agent.indexOf('trident') > -1 && agent.indexOf("rv:11.0") > -1;
                if (isIE) {
                    var reIE = new RegExp("msie (\\d+\\.\\d+);");
                    reIE.test(agent);
                    var fIEVersion = parseFloat(RegExp["$1"]);
                    if (fIEVersion == 7) {
                        return "IE/7";
                    } else if (fIEVersion == 8) {
                        return "IE/8";
                    } else if (fIEVersion == 9) {
                        return "IE/9";
                    } else if (fIEVersion == 10) {
                        return "IE/10";
                    }
                } //isIE end
                if (isIE11) {
                    return "IE/11";
                }
                //firefox
                if (agent.indexOf("firefox") > 0) {
                    return agent.match(regStr_ff);
                }
                //Safari
                if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
                    return agent.match(regStr_saf);
                }
                //Chrome
                if (agent.indexOf("chrome") > 0) {
                    return agent.match(regStr_chrome);
                }
            },
            onSubmit() {
                // this.$vuex("vuex_project_name", "xxx");
                // this.$vuex("vuex_user.name", "123");
                // this.$router.push("/");
                // return;
                this.$refs.form.validate((valid) => {
                    if (!this.form.username) {
                        this.$alert('请输入用户名！', '温馨提示', {
                            confirmButtonText: '确定'
                        });
                        return false
                    }
                    if (!this.form.password) {
                        this.$alert('请输入密码！', '温馨提示', {
                            confirmButtonText: '确定'
                        });
                        return false
                    }
                    if (!this.form.checkcode) {
                        this.$alert('请输入验证码！', '温馨提示', {
                            confirmButtonText: '确定'
                        });
                        return false
                    }
                    if (this.checked == false) {
                        this.$alert('请选择同意用户协议才能登陆！', '温馨提示', {
                            confirmButtonText: '确定'
                        });
                        return false
                    }
                    if (valid) {
                        if (this.submitting) {
                            return;
                        }
                        this.submitting = true;
                        this.$api.user.login({
                            name: this.form.username,
                            pwd: this.form.password,
                            code: this.form.checkcode
                        }).then(res => {
                            console.log('---------------')
                            console.log(res)
                            if (res.code == 200) {
                                // 登录成功
                                this.$message.success("登录成功");
                                this.$vuex("vuex_user", res.data);
                                this.$vuex("vuex_token", res.token_type + ' ' + res.access_token)
                                this.$vuex("vuex_login_time", new Date().format('yyyy-MM-dd hh:mm:ss'))
                                this.$router.push("/");
                            } else {
                                // 登录失败
                                this.$message.error(res.msg);
                                this.submitting = false;
                                this.refreshCheckCode()
                            }
                        }).catch(err => {
                            // 登录失败
                            this.$message.error(err.statusText);
                            this.submitting = false;
                            this.refreshCheckCode()
                        })
                    }
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .form-box {
        margin: 0 auto;
        width: calc(603px + 402px);

        .left-img {
            width: 402px;
            height: 506px;
        }

        .right-box {
            box-sizing: border-box;
            width: 603px;
            height: 506px;
            background-color: #fff;
            padding-top: 35px;

            .no-account {
                font-size: 12px;
                margin-top: 30px;
                color: #999999;

                a {
                    color: #238B3A;
                    text-decoration: none;
                }
            }

            .title {
                margin-bottom: 40px;
                font-size: 26px;
                color: #238B3A;
            }

            .form {
                width: 360px;
                margin: 0 auto;

                .password-box {
                    color: #808080;

                    .forget {
                        cursor: pointer;
                    }
                }

                .submit-btn {
                    background-color: #238B3A;
                    border-color: #238B3A;
                    width: 100%;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .wrap {
        background-color: #F4F7F9;
        position: absolute;
        min-height: 100%;
        width: 100%;
    }

    .logo-box {
        margin: 120px;

        .logo-tit {
            line-height: 30px;
            font-size: 26px;
            color: #238B3A;
        }

        .logo {
            height: 30px;
            border-right: 2px solid #238B3A;
            padding-right: 10px;
            margin-right: 10px;

            img {
                width: auto;
                height: 30px;
            }
        }
    }

    .text-center {
        text-align: center;
    }

    .box-card {
        width: 480px;
        margin: 200px auto;
    }

    .checkcode {
        width: 120px;
        height: 40px;
    }
</style>
